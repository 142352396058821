export interface InternalLink {
  title: string;
  path: string;
  admin: boolean;
  asPath: string;
}

export const INTERNAL_LINKS: InternalLink[] = [
  {
    title: 'Projects',
    path: '/eames/projects/',
    asPath: '/projects/',
    admin: false,
  },
  {
    title: 'Inventory',
    path: '/eames/inventory/',
    asPath: '/inventory/',
    admin: false,
  },
];
