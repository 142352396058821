import React from 'react';
import { type SingleValue } from 'react-select';

import { type Market, type MarketOption } from 'js/api';

import ModalContainer from 'js/components/partial/ModalContainer';
import Button from 'js/components/partial/Button';
import { FormGroup } from 'js/components/partial/Forms';
import MarketSelect from 'js/components/partial/MarketSelect';

import { Buttons, FormWrapper } from './styled';

interface Props {
  onClose: () => void;
  onSuccess: (marketId: number) => void;
  defaultValue?: SingleValue<MarketOption>;
  title?: string;
}

interface State {
  selectedMarket: SingleValue<MarketOption>;
}

class SelectMarketModal extends React.Component<Props, State> {
  override state: State = {
    selectedMarket: null,
  };

  override componentDidMount() {
    const { defaultValue } = this.props;
    if (defaultValue) {
      this.setState({ selectedMarket: defaultValue });
    }
  }

  private handleCloseModal = () => {
    this.props.onClose();
  };

  private handleMarketChange = (selectedMarket: SingleValue<MarketOption>) => {
    this.setState({ selectedMarket });
  };

  private handleSubmit = () => {
    const { onSuccess } = this.props;
    const { selectedMarket } = this.state;
    if (!selectedMarket) return;

    onSuccess(selectedMarket.value);
    this.handleCloseModal();
  };

  override render() {
    const { selectedMarket } = this.state;
    const { defaultValue, title } = this.props;

    return (
      <ModalContainer
        col={6}
        closeButton={false}
        onClose={this.handleCloseModal}
        small
      >
        <FormWrapper>
          <FormGroup id="market" title={title ?? 'Market'}>
            <MarketSelect
              value={selectedMarket}
              onChange={this.handleMarketChange}
            />
          </FormGroup>

          <Buttons>
            <Button onClick={this.handleCloseModal}>Cancel</Button>
            <Button
              cta
              disabled={
                !selectedMarket || selectedMarket.value === defaultValue?.value
              }
              onClick={this.handleSubmit}
            >
              OK
            </Button>
          </Buttons>
        </FormWrapper>
      </ModalContainer>
    );
  }
}

export default SelectMarketModal;
