import React, { useMemo, type ComponentType } from 'react';
import { useRouter, type Router } from 'next/router';

interface LegacyRouter {
  push: Router['push'];
  goBack: () => void;
}

export interface WithRouterProps {
  location: { pathname: string };
  params: Router['query'];
  router: LegacyRouter;
  asPath: string;
}

interface WithRouterConfig<PropsFromParams> {
  mapParamsToProps?: (params: Router['query']) => PropsFromParams;
}

/**
 * HOC that wraps a component and provides a legacy router (router-dom v5) as a prop.
 */
export function withRouter<
  P extends WithRouterProps = WithRouterProps,
  PropsFromParams extends object = object
>(Component: ComponentType<P>, config?: WithRouterConfig<PropsFromParams>) {
  function ComponentWithRouterProp(
    props: Omit<P, keyof WithRouterProps | keyof PropsFromParams>
  ) {
    const { push, back, query, pathname, asPath } = useRouter();
    const location = { pathname };

    const propsFromParams = useMemo(
      () => config?.mapParamsToProps?.(query),
      [query]
    );

    // Use the same interface as router v4 to avoid breaking changes in the components
    const router: LegacyRouter = { push, goBack: back };
    const withRouterProps: WithRouterProps = {
      params: query,
      location,
      router,
      ...propsFromParams,
      asPath,
    };

    const componentProps = {
      ...props,
      ...withRouterProps,
    } as P & WithRouterProps;

    return <Component {...componentProps} />;
  }

  return ComponentWithRouterProp;
}
