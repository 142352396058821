import React from 'react';

import theme from 'js/theme';
import { toClassNames } from 'js/utils';

interface Props {
  className?: string;
  color?: string;
}

function EditRectangle({
  className = '',
  color = theme.colors.grayLight,
}: Props) {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={toClassNames('icon', className)}
    >
      <path
        d="M 10.0523 1.3785 C 10.2947 1.1362 10.6234 1 10.9662 1 C 11.3089 1 11.6376 1.1362 11.88 1.3785 C 12.1224 1.6209 12.2585 1.9496 12.2585 2.2924 C 12.2585 2.6351 12.1224 2.9638 11.88 3.2062 L 6.0923 8.9939 L 3.6554 9.6031 L 4.2646 7.1662 L 10.0523 1.3785 Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.48306 1.29237H2.21846C1.8953 1.29237 1.58538 1.42075 1.35688 1.64925C1.12837 1.87776 1 2.18768 1 2.51083V11.04C1 11.3632 1.12837 11.6731 1.35688 11.9016C1.58538 12.1301 1.8953 12.2585 2.21846 12.2585H10.7477C11.0708 12.2585 11.3807 12.1301 11.6092 11.9016C11.8378 11.6731 11.9661 11.3632 11.9661 11.04V6.77544"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EditRectangle;
