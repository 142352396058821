import React from 'react';
import styled from 'styled-components';

import { toClassNameObj } from 'js/utils';
import { type User } from 'js/api';

function getUserInitials(user: User): string {
  if (!user) return '';
  const first = user.givenName?.charAt(0) ?? '';
  const last = user.familyName?.charAt(0) ?? '';
  return `${first}${last}`;
}

function getFullNameInitials(fullName: string): string {
  if (!fullName) return '';
  const names = fullName.split(' ');
  let initials = names[0].charAt(0);
  if (names.length > 1) {
    initials += names.pop()?.charAt(0);
  }
  return initials;
}

const Container = styled.span`
  display: inline-block;
`;

interface IconProps {
  backgroundColor: string;
  fontSize: number;
  width: number;
  height: number;
}

const Icon = styled.span<IconProps>`
  align-items: center;
  background: ${(props) => props.backgroundColor};
  border-radius: 50%;
  display: flex;
  font-size: ${(props) => props.fontSize}px;
  height: ${(props) => props.height}px;
  justify-content: center;
  text-transform: uppercase;
  width: ${(props) => props.width}px;
`;

export interface ProfileIconProps {
  backgroundColor?: string;
  className?: string;
  fullName?: string;
  size?: number;
  user?: User;
}

function ProfileIcon({
  backgroundColor = '#f9f9f9',
  className = '',
  fullName = '',
  size = 35,
  user,
}: ProfileIconProps) {
  const fontSize = size * 0.33;
  const styleProps = {
    backgroundColor,
    fontSize,
    height: size,
    width: size,
  };

  return (
    <Container {...toClassNameObj(className)}>
      <Icon {...styleProps}>
        {user ? getUserInitials(user) : getFullNameInitials(fullName)}
      </Icon>
    </Container>
  );
}

export default ProfileIcon;
