import styled, { css, type ThemeProps } from 'styled-components';

import { type Theme } from 'js/theme';
import IconLogo from 'js/icons/Logo';

export const Navbar = styled.nav`
  background: ${(props: ThemeProps<Theme>) => props.theme.colors.white};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  left: 0;
  min-height: 64px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
`;

export const Brand = styled.div`
  float: left;
  font-size: 18px;
  height: 64px;
  line-height: 20px;
  padding: 10px;

  @media (max-width: ${(props: ThemeProps<Theme>) =>
      props.theme.flexboxgrid.breakpoints.sm}em) {
    padding-left: 0;
  }
`;

export const IconLogoStyled = styled(IconLogo)`
  vertical-align: top;
`;

export const Title = styled.h2`
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.darkGreyBlue};
  display: inline-block;
  font-size: 15px;
  margin: 15px 0 0 0;
`;

export const ProfileLink = styled.div`
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.darkGreyBlue};
  float: right;
  font-size: 14px;
  font-weight: bold;
  padding-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(props: ThemeProps<Theme>) =>
      props.theme.flexboxgrid.breakpoints.sm}em) {
    padding-right: 0;
  }
`;

export const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 5px;
`;

export const ProfileName = styled.span`
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.grayLight};
  font-weight: bold;
`;

export const ProfileMarketWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const ProfileMarket = styled.span`
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.grayLight};
  font-weight: normal;
  font-size: 12px;
  line-height: 1em;
`;

export const NavMenu = styled.div`
  display: inline-block;
  padding: 22px 0;
  font-size: 14px;
  margin: 0 auto;
  border-bottom: 1px solid #e5e3ec;
`;

interface NavMenuLinkProps {
  active: boolean;
}

export const NavMenuLink = styled.span`
  position: relative;
  margin: 0 10px;
  color: #333;
  /* @TODO Is this border used ?*/
  border-right: 1px solid #333;

  &:last-child {
    border-right: none;
  }

  ${(props: NavMenuLinkProps) =>
    props.active &&
    css`
      color: #009cff;
      font-weight: 700;

      &::after {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        bottom: -25px;
        width: 100%;
        height: 4px;
        background: #009cff;
        border-radius: 2px 2px 0 0;
        transform: translateX(-50%);
      }
    `};
`;
