import React, { type ComponentType } from 'react';
import { usePopups, type PopupContextValues } from './PopupProvider';

export type WithPopupsProps = PopupContextValues;

export function withPopups<P extends WithPopupsProps>(
  Component: ComponentType<P>
) {
  function ComponentWithPopupsProps(props: Omit<P, keyof WithPopupsProps>) {
    const contextValues = usePopups();

    const componentProps = {
      ...props,
      ...contextValues,
    } as P & WithPopupsProps;

    return <Component {...componentProps} />;
  }

  return ComponentWithPopupsProps;
}
