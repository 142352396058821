import React from 'react';

import {
  withUpdateUserMutation,
  type WithUpdateUserMutationProps,
  type UpdateUserXhrVariables,
} from 'js/api';

import { track, TRACKED_EVENTS } from 'js/utils/analytics';

import { isProjectRoute, setUserToLocalStorage } from 'js/utils';
import { withUser, type WithUserProps } from 'js/utils/withUser';
import { withRouter, type WithRouterProps } from 'js/utils/withRouter';

import SelectMarketModal from '../partial/SelectMarketModal/SelectMarketModal';
import EditRectangleIcon from 'js/icons/EditRectangle';
import { EditMarketWrapper } from './styled';

type Props = WithUserProps & WithRouterProps & WithUpdateUserMutationProps;

type State = {
  isSelectMarketModalOpen: boolean;
};

class EditProfileMarketWidget extends React.PureComponent<Props, State> {
  override state = {
    isSelectMarketModalOpen: false,
  };

  private handleOpenMarketSelectModal: React.MouseEventHandler<HTMLDivElement> =
    (e) => {
      e.stopPropagation();
      this.setState({ isSelectMarketModalOpen: true });
    };

  private handleCloseMarketSelectModal = () => {
    this.setState({ isSelectMarketModalOpen: false });
  };

  private handleUpdateUserMarket = async (marketId: number) => {
    const {
      updateUserMutation,
      user,
      router,
      location: { pathname },
    } = this.props;
    const data = await updateUserMutation.mutateAsync({
      body: {
        id: user.id,
        marketId,
      },
    } as UpdateUserXhrVariables);

    track(TRACKED_EVENTS.PROFILE_EDITED, { emailAddress: data.email });
    setUserToLocalStorage(data);
    this.handleCloseMarketSelectModal();

    if (isProjectRoute(pathname)) {
      router.push({ pathname: '/eames/projects/' }, '/projects/');
      return;
    }

    // Added to prevent stale cache of Inventory tab
    location.reload();
  };

  override render() {
    const { user } = this.props;
    const { isSelectMarketModalOpen } = this.state;

    return (
      <>
        <EditMarketWrapper
          onClick={this.handleOpenMarketSelectModal}
          title="Change market"
        >
          <EditRectangleIcon />
        </EditMarketWrapper>
        {isSelectMarketModalOpen && (
          <SelectMarketModal
            onClose={this.handleCloseMarketSelectModal}
            onSuccess={this.handleUpdateUserMarket}
            defaultValue={
              user.marketId && user.marketName
                ? { value: user.marketId, label: user.marketName }
                : null
            }
          />
        )}
      </>
    );
  }
}

export default withUser(
  withRouter(withUpdateUserMutation(EditProfileMarketWidget))
);
