import React from 'react';
import Select, { type SingleValue } from 'react-select';

import {
  mapMarketsToOptions,
  GetMarketsQuery,
  type MarketOption,
  type GetMarketsQueryResult,
} from 'js/api/markets';
import IconSpinnerCircle from 'js/icons/SpinnerCircle';

interface Props {
  value: SingleValue<MarketOption> | null;
  onChange: (market: SingleValue<MarketOption>) => void;
}

export default class MarketSelect extends React.Component<Props> {
  override render() {
    const { value, onChange } = this.props;

    return (
      <GetMarketsQuery>
        {(result: GetMarketsQueryResult) => {
          const { data, isLoading } = result;
          const markets = mapMarketsToOptions(data ?? []);

          return (
            <>
              <Select value={value} onChange={onChange} options={markets} />
              {isLoading && <IconSpinnerCircle />}
            </>
          );
        }}
      </GetMarketsQuery>
    );
  }
}
