import React from 'react';
import Link from 'next/link';
import { Grid } from 'js/components/partial/FlexboxGrid';

import {
  Navbar,
  Title,
  Brand,
  IconLogoStyled,
  ProfileLink,
  ProfileName,
  NavMenu,
  NavMenuLink,
  ProfileWrapper,
  ProfileMarket,
  ProfileMarketWrapper,
} from './styled';

import EditProfileMarketWidget from 'js/components/Profile/EditProfileMarketWidget';
import ProfileIcon from 'js/components/partial/ProfileIcon';

import { withRouter, type WithRouterProps } from 'js/utils/withRouter';
import { withUser, type WithUserProps } from 'js/utils/withUser';

import { INTERNAL_LINKS } from './constants';

type Props = WithUserProps & WithRouterProps;

class Header extends React.Component<Props> {
  private renderInternalLinks = () => {
    const {
      user: { role },
      location: { pathname },
    } = this.props;

    return INTERNAL_LINKS.map((link) => {
      if (link.admin && role !== 'admin') return null;
      return (
        <Link href={link.path} as={link.asPath} key={link.path}>
          <NavMenuLink active={pathname.includes(link.path.slice(0, -1))}>
            {link.title}
          </NavMenuLink>
        </Link>
      );
    });
  };

  override render() {
    const { isAdmin, currentUser } = this.props;
    const fullName = (currentUser && currentUser.fullName) || '';

    return (
      <header>
        <Navbar>
          <Grid style={{ display: 'flex' }}>
            <Brand>
              <IconLogoStyled />
              <Title></Title>
            </Brand>
            <NavMenu>{this.renderInternalLinks()}</NavMenu>
            <ProfileLink>
              <ProfileWrapper>
                <Link href="/eames/profile" as="/profile">
                  <ProfileName>{fullName}</ProfileName>
                </Link>
                <ProfileMarketWrapper>
                  {isAdmin && <EditProfileMarketWidget />}
                  <Link href="/eames/profile" as="/profile">
                    <ProfileMarket>{currentUser.marketName}</ProfileMarket>
                  </Link>
                </ProfileMarketWrapper>
              </ProfileWrapper>
              <Link href="/eames/profile" as="/profile">
                <ProfileIcon user={currentUser} />
              </Link>
            </ProfileLink>
          </Grid>
        </Navbar>
      </header>
    );
  }
}

export default withUser(withRouter(Header));
