import React, { type ComponentType } from 'react';

import { getUserFromLocalStorage, isAdminUser } from 'js/utils';
import { User, useGetCurrentUserQuery } from 'js/api/users';

export interface WithUserProps {
  user: User;
  currentUser: User;
  isAdmin: boolean;
}

/**
 * HOC that wraps a component and provides a current user from Local Storage as a prop.
 */
export function withUser<P extends WithUserProps>(Component: ComponentType<P>) {
  function ComponentWithUserProp(props: Omit<P, keyof WithUserProps>) {
    const data = useGetCurrentUserQuery({ options: { staleTime: Infinity } });

    const user = getUserFromLocalStorage();

    if (!user) {
      throw new Error('Current user is missing in local storage.');
    }

    const isAdmin = isAdminUser(user);

    const withUserProps: WithUserProps = {
      user,
      currentUser: data.data ?? user,
      isAdmin,
    };

    const componentProps = {
      ...props,
      ...withUserProps,
    } as P & WithUserProps;

    return <Component {...componentProps} />;
  }

  return ComponentWithUserProp;
}
