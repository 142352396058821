import styled from 'styled-components';

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin: 0 10px;
    min-width: 100px;
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  min-height: 150px;
  text-align: left;

  label {
    display: block;
    width: 100%;
    text-align: left;
  }

  .icon-spinner-circle {
    z-index: 3;
  }
`;
