import React from 'react';
import Image from 'next/image';
import LogoButtonImage from './images/vesta_logo.png';
import { toClassNames } from 'js/utils';

interface Props {
  className?: string;
}

function Logo({ className = '' }: Props) {
  return (
    <Image
      src={LogoButtonImage}
      alt="vestaHome"
      className={toClassNames('icon', 'icon-logo', className)}
      width={150}
      height={37}
      // @ts-ignore - @TODO
      viewBox="0 0 26 37"
    />
  );
}

export default Logo;
