import React, { type PropsWithChildren, type MouseEvent } from 'react';
import { Grid, Col } from 'js/components/partial/FlexboxGrid';

import {
  Background,
  Content,
  Message,
  Button,
  CloseButton,
  RoomCloseButton,
  RowStyled,
  type ContentProps,
  type ButtonProps,
  type BackgroundProps,
} from './styled';

import IconCross from 'js/icons/Cross';

import theme from 'js/theme';
import { handleStopPropagation } from 'js/utils';
import {
  withPopups,
  type WithPopupsProps,
} from 'js/components/partial/PopupProvider/withPopups';

export { Message, Button };

interface Props
  extends WithPopupsProps,
    ContentProps,
    ButtonProps,
    BackgroundProps {
  className?: string;
  closeButton?: boolean;
  freeze?: boolean;
  mdCol?: number;
  smCol?: number;
  col?: number;
  onClose: () => void;
}

class ModalContainer extends React.Component<PropsWithChildren<Props>> {
  static defaultProps = {
    background: theme.colors.dark40,
    freeze: true,
  };

  override componentDidMount() {
    const { showPopup, freeze } = this.props;
    showPopup('modal');

    if (freeze) document.body.classList.add('freeze');

    document.addEventListener('keydown', this.handleClose);
  }

  override componentWillUnmount() {
    const { hidePopup, freeze } = this.props;
    hidePopup('modal');

    if (freeze) document.body.classList.remove('freeze');

    document.removeEventListener('keydown', (ev) => this.handleClose(ev));
  }

  private handleClose = (e: MouseEvent | KeyboardEvent) => {
    const { onClose } = this.props;

    // @TODO
    if (e.type === 'keydown' && (e as KeyboardEvent).keyCode !== 27) {
      return false;
    }
    if (typeof onClose === 'function') {
      onClose();
    }

    return;
  };

  private renderCloseButton() {
    const { room, product } = this.props;
    const Button = room || product ? RoomCloseButton : CloseButton;
    return (
      <Button onClick={this.handleClose}>
        <IconCross />
      </Button>
    );
  }

  override render() {
    const {
      room,
      small,
      loading,
      product,
      closeButton,
      className,
      background,
      contentBackground,
      children,
      col,
      smCol,
      mdCol,
      hide,
    } = this.props;

    return (
      <Background
        className={className}
        background={background}
        onClick={this.handleClose}
        hide={hide}
      >
        <Grid>
          <RowStyled>
            <Col xs={col} sm={smCol || col} md={mdCol || smCol || col}>
              <Content
                className="product-item"
                small={small}
                loading={loading}
                room={room}
                product={product}
                onClick={handleStopPropagation}
                contentBackground={contentBackground}
              >
                {closeButton && this.renderCloseButton()}
                {children}
              </Content>
            </Col>
          </RowStyled>
        </Grid>
      </Background>
    );
  }
}

export default withPopups(ModalContainer);
