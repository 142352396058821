import styled, { type ThemeProps } from 'styled-components';
import { Grid, Col } from 'js/components/partial/FlexboxGrid';

import { type Theme } from 'js/theme';
import Button, {
  type Props as ButtonProps,
} from 'js/components/partial/Button';
import { Input as FormInput } from '../partial/Forms';

export const GridWithMargin = styled(Grid)`
  margin-top: 2rem;
`;

export const RightAlignedCol = styled(Col)`
  text-align: right;
`;

export const UserName = styled.p`
  font-weight: 600;
  margin-bottom: 3px;
`;

export const UserRole = styled.p`
  font-style: italic;
  margin-bottom: 3px;
`;

export const UserDetails = styled.span`
  margin-right: 10px;
`;

export const UserDetailsHighlight = styled.span`
  color: ${(props: ThemeProps<Theme>) => props.theme.colors.azure};
`;

export const EditProfileLink = styled.span`
  display: inline-block;
  font-style: normal;
  margin-left: 10px;
`;

export const ButtonStyled = styled(Button)<ButtonProps>`
  text-transform: none;
`;

export const EditForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const EditFormGroup = styled.div`
  width: 320px;
  margin: 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionsFormGroup = styled.div`
  width: 200px;
  margin: 20px 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const Label = styled.label`
  color: #636c7e;
`;

export const Input = styled(FormInput)`
  width: 220px;
`;

export const ProfileImage = styled.div`
  position: relative;
  margin: 20px auto -10px auto;
  width: 260px;
  height: 160px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .edit {
    position: absolute;
    bottom: 0;
    width: 260px;
    height: 50px;
    padding: 10px 60px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: rgba(0, 0, 0, 0.4);

    .img-action {
      width: 55px;
      border-radius: 3px;
      border: 1px solid transparent;
      cursor: pointer;
      display: inline-block;
      font-size: 12px;
      line-height: 1.42857;
      outline: 0px;
      padding: 1px 5px;
      position: relative;
      text-align: center;
      touch-action: manipulation;
      vertical-align: middle;
      white-space: nowrap;
      color: rgb(255, 255, 255);
      background-color: rgb(0, 156, 255);
      margin: 0 5px;
    }
  }
`;

export const EditMarketWrapper = styled.div`
  margin-right: 5px;
  padding: 3px;
  display: inline-block;
  cursor: pointer;
`;

export const MarketSelectWrapper = styled.div`
  width: 220px;
`;
