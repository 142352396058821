import styled, { css, keyframes, type ThemeProps } from 'styled-components';
import { Row } from 'js/components/partial/FlexboxGrid';

import { type Theme } from 'js/theme';
import DefaultButton from 'js/components/partial/Button';

const backgroundAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const backgroundAnimationRule = css`
  ${backgroundAnimation} 0.3s ease-in-out;
`;

const contentAnimation = keyframes`
  0% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
`;

const contentAnimationRule = css`
  ${contentAnimation} 0.3s ease-in-out;
`;

export interface BackgroundProps {
  hide?: boolean;
  background?: string;
}

export const Background = styled.div<BackgroundProps>`
  top: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 40px 0; // top & bottom spacing
  position: absolute;
  align-items: center;
  justify-content: center;
  background: ${(props: BackgroundProps) => props.background};
  animation: ${backgroundAnimationRule};
  ${(props) =>
    props.hide
      ? css`
          display: none;
        `
      : ''}
  &.rooms_modal {
    display: none;
  }
  &.open {
    display: flex !important;
  }

  ${() =>
    !window.iPad
      ? css`
          @media (min-width: ${(props: ThemeProps<Theme>) =>
              props.theme.flexboxgrid.breakpoints.sm}em) {
            position: fixed;
          }
        `
      : ''};
`;

export interface ContentMarginProps {
  room?: boolean;
  product?: boolean;
  small?: boolean;
}

function getContentMargin(props: ContentMarginProps) {
  if (props.room) {
    return '';
  }
  if (props.product) {
    return css`
      margin: 0 10px;
    `;
  }
  if (props.small) {
    return css`
      margin: 0 5px;
    `;
  }
  return css`
    margin: 0 -35px;
  `;
}

interface ContentPaddingProps {
  product?: boolean;
  loading?: string;
  room?: boolean;
  small?: boolean;
}

function getContentPadding(props: ContentPaddingProps) {
  if (props.product) {
    return css`
      padding: 23px 50px 76px 50px;
    `;
  }
  if (props.loading === 'true') {
    return css`
      padding: 150px 100px 60px 100px;
    `;
  }
  if (props.room) {
    return css`
      padding: 30px 0;
    `;
  }
  if (props.small) {
    return css`
      padding: 38px 41px 32px 38px;
    `;
  }
  return css`
    padding: 87px 74px 62px 75px;
  `;
}

export interface ContentProps extends ContentPaddingProps, ContentMarginProps {
  contentBackground?: string;
}

export const Content = styled.div<ContentProps>`
  position: relative;
  border-radius: 6px;
  background: ${(props: ContentProps & ThemeProps<Theme>) =>
    props.contentBackground
      ? props.contentBackground
      : props.theme.colors.white};
  box-shadow: 0 0 40px 0
    ${(props: ContentProps & ThemeProps<Theme>) =>
      props.contentBackground === 'transparent'
        ? 'none'
        : props.theme.colors.black30};
  animation: ${contentAnimationRule};

  ${getContentMargin};
  ${getContentPadding};

  ${(props) =>
    !props.room && !props.product
      ? css`
          text-align: center;
        `
      : ''};
`;

function getMessageMargin(props: ContentPaddingProps) {
  if (props.room) {
    return css`
      margin: 30px 0 15px;
    `;
  }
  if (props.loading) {
    return css`
      margin: 0;
    `;
  }
  if (props.small) {
    return css`
      margin: 0 0 45px 0;
    `;
  }
  return css`
    margin: 0 0 61px 0;
  `;
}

export const Message = styled.h3<ContentPaddingProps>`
  font-size: 16px;

  ${getMessageMargin};
  ${(props) =>
    props.loading === 'true'
      ? css`
          color: ${(props: ThemeProps<Theme>) => props.theme.colors.dark};
          font-weight: 400;
          line-height: 1.56em;
        `
      : css`
          line-height: 1.3em;
        `};
`;

export interface ButtonProps {
  first?: boolean;
  target?: string;
}

export const Button = styled(DefaultButton)<ButtonProps>`
  width: 140px;
  ${(props) =>
    !props.first
      ? css`
          margin-left: 20px;
        `
      : ''};
`;

// Ported from Bootstrap's .btn
const GenericButton = styled.button`
  border: 0;
  height: 40px;
  padding: 10px 0;
  text-transform: capitalize;
  display: inline-block;
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background: none;
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 20px;
  user-select: none;
`;

export const CloseButton = styled(GenericButton)`
  top: 40px;
  padding: 0;
  z-index: 1;
  right: 50px;
  width: 40px;
  height: 40px;
  position: fixed;
  border-radius: 50%;
  background: ${(props: ThemeProps<Theme>) => props.theme.colors.duckEggBlue};

  svg {
    top: 50%;
    left: 50%;
    width: 14px;
    height: 14px;
    position: absolute;
    margin: -7px 0 0 -7px;

    .color {
      fill: ${(props: ThemeProps<Theme>) => props.theme.colors.azure};
    }
  }

  &:hover {
    background-color: ${(props: ThemeProps<Theme>) =>
      props.theme.colors.duckEggBlueDarken};

    svg .color {
      fill: ${(props: ThemeProps<Theme>) => props.theme.colors.azureHover};
    }
  }
`;

export const RoomCloseButton = styled.button`
  border: 0;
  top: 15px;
  right: 15px;
  background: none;
  position: absolute;

  svg .color {
    fill: #999;
  }
`;

export const RowStyled = styled(Row)`
  justify-content: center;
`;
